<template>
	<component :is="tagNm || 'span'">
		<!--div class="s-mark" v-if="data.specialGradeYn == 'Y'"><img src="/images/admin/s-mark.png" alt="스페셜마크"/></div-->
		<grade-img class="rank" type="TOT_EVAL_ADM" :grade="data.totEvalGradeCd" :specialYn="data.specialGradeYn" v-if="showGrade" :class="corpNm == undefined || corpNm == '' ? '' : 'ml-0'"/>
		<span class="name" v-if="corpNm == undefined || corpNm == ''">
			{{data.tecMberNm || data.mberNm}} ({{data.gender | gender }} / {{data.birthYear||data.birthYyyymmdd | birthYear}})
			<slot name="after">{{after}}</slot>
		</span>
		<span class="name" v-else style="font-size:17px;">
			{{data.tecMberNm || data.mberNm}} ({{data.gender | gender }} / {{data.birthYear||data.birthYyyymmdd | birthYear}} / 참여소속 : {{corpNm}})
			<slot name="after">{{after}}</slot>
		</span>
	</component>
</template>

<script>
import gradeImg from "@/components/highpro/GradeImg.vue";
export default {
	components: {
		gradeImg
	},
	props: {
		value: Object,
		corpNm : String,
		tagNm: String,
		tecMberSeq: {},
		interestYn: {},
		after: String,

		showGrade: {type:Boolean, default:true},
	},
	data(){
		return {
		};
	},
	watch: {
		tecMberSeq(){
			this.search(this.tecMberSeq);
		}
	},
	mounted(){
		if(this.tecMberSeq){
 			this.search(this.tecMberSeq);
		}
	},
	methods: {
        search(tecMberSeq){
            var param = {}
            param.tecMberSeq = tecMberSeq;
            param.interestYn = this.interestYn;
            this.$.httpPost('/api/mem/prf/getTecSummary', param)
                .then(res => { this.$emit('input', res.data); });
		}
	},
	computed: {
		data(){
			if(this.value && Object.keys(this.value).length > 0){
				return this.value;
			} else {
				return {};
			}
		}
	}
}
</script>