<template>
	<div class="notice_popup special">
		<div class="close" @click="$emit('close')">
			<img src="/images/close.png" alt="닫기"/>
		</div>
		<div class="tit">스페셜 등급 관리</div>
		<tec-summary tagNm="div" class="member" :value="data" after=" 님에게"></tec-summary>
		<div class="sp_rank">
			<SelectComp type="radio" v-model="data.specialGradeYn" title="스페셜등급여부" :list="specialGradeYn" v-slot="slot" :disabled="!editable">
				<template v-if="slot.item.cd == 'Y'"><span></span><img src="/images/admin/s-mark00_popup.png" alt="스페셜마크"/>스페셜 등급 부여</template>
				<template v-else><span></span><img src="/images/admin/s-mark01_popup.png" alt="스페셜마크"/>스페셜 등급 삭제</template>
			</SelectComp>
		</div>
		<InputComp type="textarea" v-model="data.specialGradeReasonCont" title="스페셜등급사유" placeholder="사유를 입력 해 주세요!  ※필수입력" :rules="{required:true}" :showError="false" :readonly="!editable"/>
		<div class="select-btn" v-if="editable">
			<div class="btn" @click="$emit('close')">취소</div>
			<div class="btn" @click="save()">승인</div>
		</div>
	</div>
</template>
<script>
import tecSummary from "@/components/highpro/TecSummary.vue";

export default {
	components: {tecSummary},
	props: { param:Object },
	data() {
		return {
			data: {},
			specialGradeYn : {'Y':'스페셜 등급 부여', 'N':'스페셜 등급 삭제'},
		};
	},
	mounted(){
		this.$.httpPost('/api/mem/adm/tec/getSpecialGrade', {tecMberSeq:this.param.tecMberSeq})
			.then(res => {
				this.data = res.data;
			}).catch(this.$.httpErrorCommon);
	},
	methods: {
		save(){
			var tecMberSeq = this.param.tecMberSeq;
			var specialGradeYn = this.data.specialGradeYn;
			var specialGradeReasonCont = this.data.specialGradeReasonCont;
			this.$.httpPost('/api/mem/adm/tec/setSpecialGrade', {tecMberSeq, specialGradeYn, specialGradeReasonCont})
				.then(() => {
					alert(this.specialGradeYn[specialGradeYn] + '를 완료하였습니다.');
					this.$emit('close', true);
				}).catch(this.$.httpErrorCommon);
		},
	},
	computed : {
		editable(){
			return this.$store.state.userInfo.mberDivCd == '00';
		}

	}
};
</script>
